import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import './seguridad_mobile.css'

import NavBar from '../../components/navbar'
import FooterMobile from '../../components/common/footer/mobile'

const SeguridadMobile = () => {
  const { t } = useTranslation();
  return (
    <div className='seguridad-mobile-container'>
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <NavBar mobile={true} />
      <section className='seguridad-mobile-section'>
        <p className='seguridad-mobile-title'>{t('security_text01')}</p>
        <div className='who-are-we-mobile-img-box'>
          <img
            alt="who-are-we-mobile-img"
            src="/who_are_we/who_are_we/mobile/Ilustration.svg"
            className="who-are-we-mobile-img"
          />
        </div>
        <p className='seguridad-mobile-text'>{t('security_text02')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text03')}</p>
        <p className='seguridad-mobile-text'>{t('security_text04')}</p>
        <p className='seguridad-mobile-text'>{t('security_text05')}</p>
        <p className='seguridad-mobile-text'>{t('security_text06')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text07')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text08')}</p>
        <p className='seguridad-mobile-text'>{t('security_text09')}</p>
        <p className='seguridad-mobile-text'>{t('security_text10')}</p>
        <p className='seguridad-mobile-text'>{t('security_text11')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text12')}</p>
        <p className='seguridad-mobile-text'>{t('security_text13')}</p>
        <p className='seguridad-mobile-text'>{t('security_text14')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text15')}</p>
        <p className='seguridad-mobile-text'>{t('security_text16')}</p>
        <p className='seguridad-mobile-text'>{t('security_text17')}</p>
        <p className='seguridad-mobile-text'>{t('security_text18')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text19')}</p>
        <p className='seguridad-mobile-text'>{t('security_text20')}</p>
        <p className='seguridad-mobile-text'>{t('security_text21')}</p>
        <p className='seguridad-mobile-text'>{t('security_text22')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text23')}</p>
        <p className='seguridad-mobile-text'>{t('security_text24')}</p>
        <p className='seguridad-mobile-text'>{t('security_text25')}</p>
        <p className='seguridad-mobile-text'>{t('security_text26')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text27')}</p>
        <p className='seguridad-mobile-text'>{t('security_text28')}</p>
        <p className='seguridad-mobile-text'>{t('security_text29')}</p>
        <p className='seguridad-mobile-text'>{t('security_text30')}</p>
        <p className='seguridad-mobile-subtitle'>{t('security_text31')}</p>
        <p className='seguridad-mobile-text'>{t('security_text32')}</p>
        <p className='seguridad-mobile-text'>{t('security_text33')}</p>
      </section>
      <FooterMobile />
    </div>
  )
}

export default SeguridadMobile
