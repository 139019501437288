import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './styles.css';

import LanguageSelect from '../../../languageSelect/languageSelect';

const FooterTablet = () => {
  const { t } = useTranslation();

  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <footer className="footer-tablet-section">
      <div className="footer-tablet-container">
        <div className='footer-tablet-img-box'>
          <img
            alt="picaio-logo"
            src="/common/header/logo.png"
            className="footer-tablet-logo"
          />
        </div>
        <div className='footer-tablet-pages'>
          <div className="footer-tablet-pages-column footer-tablet-column-01">
            <Link onClick={scrollToTop} to="/" className="footer-tablet-link">{t("nav_text01")}</Link>
            <Link onClick={scrollToTop} to="/casos_uso" className="footer-tablet-link">{t("nav_text02")}</Link>
            <Link onClick={scrollToTop} to="/tecnologia" className="footer-tablet-link">{t("nav_text03")}</Link>
          </div>
          <div className="footer-tablet-pages-column footer-tablet-column-02">
            <Link onClick={scrollToTop} to="/quienes_somos" className="footer-tablet-link">{t("nav_text04")}</Link>
            <Link onClick={scrollToTop} to="/contacto" className="footer-tablet-link">{t("nav_text05")}</Link>
            <LanguageSelect className='footer-lang-box' />
          </div>
        </div>
        <div className='footer-tablet-social-box'>
          <a href='https://www.linkedin.com/company/picaioai' target="_blank">
            <img
              alt="picaio-socal-logo"
              src="/footer/social.png"
              className="footer-tablet-social-img"
            />
          </a>
        </div>
        <div className='footer-tablet-copyright-box'>
          <div className='footer-tablet-divider'></div>
          <p className='footer-tablet-copyright-text'>
            <span>{t("footer_text01")}</span>
            <Link onClick={scrollToTop} to="/terminos-condiciones"><span>{t("footer_text02")}</span></Link>
            <span>{t("footer_text03")}</span>
            <Link onClick={scrollToTop} to="/aviso-privacidad"><span>{t("footer_text04")}</span></Link>
            <span>{t("footer_text03")}</span>
            <Link onClick={scrollToTop} to="/seguridad"><span>{t("footer_text05")}</span></Link>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default FooterTablet
