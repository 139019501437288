import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import './seguridad_desktop.css'

import LanguageSelect from '../../components/languageSelect/languageSelect'
import Footer from '../../components/common/footer/desktop'

const SeguridadDesktop = () => {

  const { t } = useTranslation();
  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <div className="seguridad-container">
      <Helmet>
        <title>Picaio</title>
        <meta property="og:title" content="Picaio" />
      </Helmet>
      <header className="header">
        <div className='header-content'>
          <div className='header-logo'>
            <Link onClick={scrollToTop} to="/" className="header-logo-link">
              <img
                alt="picaio-logo"
                src="/common/header/logo.png"
              />
            </Link>
          </div>
          <div class='header-nav-list'>
            <div className='header-nav-views'>
              <Link onClick={scrollToTop} to="/" className="header-link">{t("nav_text01")}</Link>
              <Link onClick={scrollToTop} to="/casos_uso" className="header-link">{t("nav_text02")}</Link>
              <Link onClick={scrollToTop} to="/tecnologia" className="header-link">{t("nav_text03")}</Link>
              <Link onClick={scrollToTop} to="/quienes_somos" className="header-link">{t("nav_text04")}</Link>
            </div>
            <div className='header-btn-box'>
              <Link onClick={scrollToTop} to="/contacto" className="header-btn">{t("nav_text05")}</Link>
            </div>
            <div className='header-lang-box'><LanguageSelect /></div>
          </div>
        </div>
      </header>
      <section className='seguridad-section'>
        <p className='seguridad-title'>{t('security_text01')}</p>
        <img
          alt="seguridad-image"
          src="/who_are_we/who_are_we/Ilustration.svg"
          className="seguridad-image"
        />
        <p className='seguridad-text'>{t('security_text02')}</p>
        <p className='seguridad-subtitle'>{t('security_text03')}</p>
        <p className='seguridad-text'>{t('security_text04')}</p>
        <p className='seguridad-text'>{t('security_text05')}</p>
        <p className='seguridad-text'>{t('security_text06')}</p>
        <p className='seguridad-subtitle'>{t('security_text07')}</p>
        <p className='seguridad-subtitle'>{t('security_text08')}</p>
        <p className='seguridad-text'>{t('security_text09')}</p>
        <p className='seguridad-text'>{t('security_text10')}</p>
        <p className='seguridad-text'>{t('security_text11')}</p>
        <p className='seguridad-subtitle'>{t('security_text12')}</p>
        <p className='seguridad-text'>{t('security_text13')}</p>
        <p className='seguridad-text'>{t('security_text14')}</p>
        <p className='seguridad-subtitle'>{t('security_text15')}</p>
        <p className='seguridad-text'>{t('security_text16')}</p>
        <p className='seguridad-text'>{t('security_text17')}</p>
        <p className='seguridad-text'>{t('security_text18')}</p>
        <p className='seguridad-subtitle'>{t('security_text19')}</p>
        <p className='seguridad-text'>{t('security_text20')}</p>
        <p className='seguridad-text'>{t('security_text21')}</p>
        <p className='seguridad-text'>{t('security_text22')}</p>
        <p className='seguridad-subtitle'>{t('security_text23')}</p>
        <p className='seguridad-text'>{t('security_text24')}</p>
        <p className='seguridad-text'>{t('security_text25')}</p>
        <p className='seguridad-text'>{t('security_text26')}</p>
        <p className='seguridad-subtitle'>{t('security_text27')}</p>
        <p className='seguridad-text'>{t('security_text28')}</p>
        <p className='seguridad-text'>{t('security_text29')}</p>
        <p className='seguridad-text'>{t('security_text30')}</p>
        <p className='seguridad-subtitle'>{t('security_text31')}</p>
        <p className='seguridad-text'>{t('security_text32')}</p>
        <p className='seguridad-text'>{t('security_text33')}</p>
      </section>
      <Footer />
    </div>
  )
}

export default SeguridadDesktop
