import React from 'react'
import { useMediaQuery } from 'react-responsive'

import SeguridadDesktop from './seguridad/seguridad_desktop'
import SeguridadTablet from './seguridad/seguridad_tablet'
import SeguridadMobile from './seguridad/seguridad_mobile'

const Seguridad = () => {
  const isMobile = useMediaQuery({ query: "(min-width: 360px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 744px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1440px)" });

  return <>
    {isMobile && !isTablet && !isDesktop && <SeguridadMobile />}
    {isTablet && !isDesktop && <SeguridadTablet />}
    {isDesktop && <SeguridadDesktop />}
  </>
}

export default Seguridad
