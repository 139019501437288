import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import "./i18nextInit";

import './style.css'
import Home from './views/home'
import CasosUso from './views/casos_uso'
import Tecnologia from './views/tecnologia'
import QuienesSomos from './views/quienes_somos'
import Contacto from './views/contacto'
import AvisoPrivacidad from './views/aviso-privacidad'
import TerminosCondiciones from './views/terminos_condiciones'
import Seguridad from './views/seguridad'

const App = () => {
  return (
    <Suspense fallback="...">
      <Router>
        <Route exact component={Home} path="/" />
        <Route exact component={CasosUso} path="/casos_uso" />
        <Route exact component={Tecnologia} path="/tecnologia" />
        <Route exact component={QuienesSomos} path="/quienes_somos" />
        <Route exact component={Contacto} path="/contacto" />
        <Route exact component={AvisoPrivacidad} path="/aviso-privacidad" />
        <Route exact component={TerminosCondiciones} path="/terminos-condiciones" />
        <Route exact component={Seguridad} path="/seguridad" />
      </Router>
    </Suspense>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
